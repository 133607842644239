<template>
    <div class="cart">
        <h3>Human Resources</h3>
        <h6>人力资源</h6>
        <div class="box">
            现有员工<span class="colorText">114名</span>，其中博士<span class="colorText">3名</span>、硕士<span class="colorText">23名</span>；中级职称以上占<span class="colorText">67%</span>，其中正高级<span class="colorText">7名</span>、副高级<span class="colorText">32名</span>、中级<span class="colorText">37名</span>；国家安全生产专家组成员<span class="colorText">1名</span>、安全生产检测检验机构资质认定评审员<span class="colorText">8名</span>、国家一级计量标准考评员<span class="colorText">4名</span>、注册安全工程师<span class="colorText">33名</span>。
        </div>
    </div>
</template>
<script>
export default {
    name: 'personResource'
}
</script>
<style scoped>
h6 {
    margin-bottom: 18px;
}
.box {
    font-size: 20px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333;
    line-height: 36px;
}
</style>